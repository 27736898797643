import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IUserApp, IUserInfo, IUserProfileData } from '../../../Shared/Models/user.model';
import { UserApiFacade } from '../../../Facade/ApiFacade/user.facade';
import { SessionStateService } from 'src/app/Shared/Services/session-state.service';
import { Observable, Subscription } from 'rxjs';
import { ITaskSummary } from 'src/app/Shared/Models/task.model';
import { EditModeService } from 'src/app/Shared/Services/edit-mode.service';
import { PlaytikaSiteAdminService } from 'src/app/Shared/Services/playtika-site-admin.service';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { MainMenu } from 'src/app/Shared/Models/mainMenu.model';
import { AppsService } from 'src/app/Pages/Apps/apps.service';
import { AnnouncementsGraphFacade } from 'src/app/Facade/GraphApiFacade/announcements-graph.facade';
import { DatePipe } from '@angular/common';
import { TasksFacade } from 'src/app/Facade/ApiFacade/tasks.facade';
import { IBanner } from 'src/app/Shared/Models/banner.interface';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { ISideItemCategory } from 'src/app/Shared/Models/sideItem.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Input() mainMenu: MainMenu[];
  @Input() banners: IBanner[];
  isDashboardOpened: boolean;
  @Input() displaySelfyModule: boolean | undefined = undefined;
  @Input() sideItemsCategories: ISideItemCategory[];
  @Output() closeModalEmiter = new EventEmitter();
  @Output() openModalEmiter = new EventEmitter();


  personalIsOpened = false;
  userBasicInfo: IUserInfo;
  userTaskSummary$: Observable<ITaskSummary>;
  tasksCountToSee: number = 0;
  notificationsCountToSee: number = 0;
  isEditModeActive: boolean;
  isMainSiteAdmin: boolean = false;
  isWikiSiteAdmin: boolean = false;
  userProfileInfo: IUserProfileData;

  favoriteApps: IUserApp[] = [];
  allApps: IUserApp[];
  editModeSubscription: Subscription;
  dashboardOpenSubscription: Subscription;
  userSubscription$: Subscription = new Subscription();

  datepipe: DatePipe = new DatePipe('en-US');
  today = this.datepipe.transform(Date.now(), 'MM-dd');

  playti4u_url = environment.Playti4U_url;
  zoneOrProfileLabel = ''

  constructor(
    private userFacade: UserApiFacade,
    private sessionStateService: SessionStateService,
    private editModeService: EditModeService,
    private userProfileService: UserProfileService,
    private playtikaSiteAdminService: PlaytikaSiteAdminService,
    private appsService: AppsService,
    private tasksFacade: TasksFacade,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initProfile();
    this.initUserData();
    this.dashboardOpenSubscription = this.dashboardService.isOpened$.subscribe(isOpened => {
      if (isOpened == false) {
        setTimeout(() => {
          this.isDashboardOpened = isOpened;
        }, 340);
      } else {
        this.isDashboardOpened = isOpened;
      }

    });
  }

  initUserData() {
    let userMail = (this.userBasicInfo && this.userBasicInfo.userPrincipalName) ?? undefined;
    this.userSubscription$ = this.userFacade.getUserProfile(userMail)
      .subscribe(profile => {
        this.userProfileInfo = profile;
      });
  }

  ngOnDestroy(): void {
    this.dashboardOpenSubscription.unsubscribe();
  }

  initProfile() {
    //we need 0.5 sec timeout because when we leaving or refreshing page - we need firs save data and after that get new
    setTimeout(() => {
      this.getFavoriteApps();
    }, 500);

    this.editModeService.editModeStateSource$.subscribe((value) => {
      this.isEditModeActive = value;
    });

    this.playtikaSiteAdminService.playtikaSiteAdminSource$.subscribe(
      (source) => {
        if (source.isMainSiteAdmin) {
          this.isMainSiteAdmin = source.isMainSiteAdmin;
        }

        if (source.isWikiSiteAdmin) {
          this.isWikiSiteAdmin = source.isWikiSiteAdmin;
        }
      }
    );

    this.userProfileService.userProfileSource$.subscribe((user) => {
      if (user && user.userPrincipalName) {
        this.userBasicInfo = user;
      }
    });



    this.tasksFacade.newTasksCountSource$.subscribe((count) => {
      this.tasksCountToSee = count;
    });

    this.userTaskSummary$ = this.userFacade.GetUserTasksSummary();

    this.editModeSubscription = this.appsService.isEditComplete$.subscribe(
      (data) => {
        if (data) {
          //we need 0.5 sec timeout because when we leaving or refreshing page - we need firs save data and after that get new
          setTimeout(() => {
            this.getFavoriteApps();
          }, 500);
        }
      }
    );

  }

  getFavoriteApps() {
    this.userFacade.getUserApps().subscribe(
      (res) => {
        this.allApps = res.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );
        this.favoriteApps = [];
        for (let i = 0; i < this.allApps.length; i++) {
          if (this.allApps[i].isFavorite) {
            this.favoriteApps.push(this.allApps[i]);
          }
        }
        this.appsService.isEditComplete$.next(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  navigateToPersonalZone() {
    if (this.userProfileInfo) {
      //Differentiate - employees only
      if (this.userProfileInfo.organizationalLevel
        && this.userProfileInfo.organizationalLevel != 'Contingent' && this.userProfileInfo.organizationalLevel != null
        && this.userProfileInfo.organizationalLevel != '') {
        if (this.userProfileInfo.country) {
          if (this.userProfileInfo.country.toLowerCase() == 'israel') {
            this.openPlayti4U();
          } else {
            if (this.userProfileInfo.isManager || this.displaySelfyModule) {
              this.openDashboard();
            }
            else {
              this.router.navigate(['/personal-area/profile']);
            }
          }
        }
      } else
        this.router.navigate(['/personal-area/profile']);
    }
  }

  private openPlayti4U() {
    let a = document.createElement('a');
    a.href = this.playti4u_url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.removeChild(a);
  }

  isEditMenuModeChange(value: boolean) {
    this.editModeService.isEditModeActiveState(value);
  }
  closeHeaderModalEmitter() {
    this.closeModalEmiter.emit();
  }

  closeDashboardPlease() {
    this.dashboardService.close();
  }

  openDashboard() {
    this.openModalEmiter.emit();
  }
}
