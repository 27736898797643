import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';
import { IBirthdayWish, IEmployeeBirthdaysData, IPerson, IUserPermitions } from 'src/app/Shared/Models/user.model';
import { MenuSidePopupGraphFacade } from 'src/app/Facade/GraphApiFacade/menu-side-popup-graph.facade';
import { IMenuSidePopupBanner } from 'src/app/Shared/Models/side-menu-popup.interface';
import { SideItemsGraphFacade } from 'src/app/Facade/GraphApiFacade/sideItems-graph.facade';
import { MainMenuGraphFacade } from 'src/app/Facade/GraphApiFacade/main-menu-graph.facade';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { OutlookCalendarGraphFacade } from 'src/app/Facade/GraphApiFacade/outlookCalendar-graph.facade';
import { IOutlookCalendar } from 'src/app/Shared/Models/outlookCalendar.inteface';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MemberOfService } from 'src/app/Shared/Services/member-of.service';
import { MainMenu } from '../../../Shared/Models/mainMenu.model';
import { DashboardService } from '../../Services/DashboardService/DashboardService';
import { ArticlesFacade } from 'src/app/Facade/GraphApiFacade/articles-graph.facade';
import { DatePipe } from '@angular/common';
import { AnnouncementsGraphFacade } from 'src/app/Facade/GraphApiFacade/announcements-graph.facade';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { BannerFacade } from 'src/app/Facade/SpFacade/banner.facade';
import { TasksFacade } from 'src/app/Facade/ApiFacade/tasks.facade';
import { IBanner, IBannerSource } from 'src/app/Shared/Models/banner.interface';
import { DashboardsApiFacade } from 'src/app/Facade/ApiFacade/dashboard.facade';
import { AnimationEvent } from "@angular/animations";
import { Subscription } from 'rxjs';
import { GoogleTagService } from 'src/app/Shared/Services/google-tag.service';
import { EnGaEventType } from 'src/app/Shared/Models/google-analytics.interface';
import { ISideItemCategory } from 'src/app/Shared/Models/sideItem.interface';

@Component({
  selector: 'global-sidebar-with-dashboard',
  templateUrl: './sidebar-with-dashboard.component.html',
  styleUrls: ['./sidebar-with-dashboard.component.scss'],
  animations: [
    trigger("parentSlide", [
      state('true', style({ width: 'calc({{sidebarDashboardWidth}}px)' }), { params: { sidebarDashboardWidth: 1173 } }),
      state('false', style({ width: 0 })),
      transition('true <=> false', animate('300ms')),
    ])
  ]
})
export class SidebarWithDashboardComponent implements OnInit, OnDestroy {

  mainMenu: MainMenu[];
  dashboardSourceSubscription: Subscription;
  sideCategoriesItemsSubscription: Subscription;

  mostRecentPeople: IPerson[];
  topBanner!: IMenuSidePopupBanner;
  birthDayBanner!: IMenuSidePopupBanner;
  leftCard!: IMenuSidePopupBanner;
  middleCard!: IMenuSidePopupBanner;
  outlookCalendar: IOutlookCalendar[];
  sidebarDashboardWidth: number;
  sidebarDashboardMaxWidth: number = 1173;
  user: any;
  datepipe: DatePipe = new DatePipe('en-US');
  todayBirthdays = this.datepipe.transform(Date.now(), 'MM-dd');
  yestodayBirthdays = this.datepipe.transform(Date.now() - 1000 * 60 * 60 * 24, 'MM-dd');
  birthdayWishes: IBirthdayWish[];
  birthday: IEmployeeBirthdaysData;
  canShow = false;
  mainMenuFooterBanners: IBanner[] = [];
  displaySelfyModule: boolean = false;
  isDashboardOpen: boolean = false;
  isDashboardChangingState: boolean = false;
  isDarkMode: boolean = false;
  sideItemsCategories: ISideItemCategory[];


  @Output() closeModalEmiter = new EventEmitter();

  constructor(
    private memberOfService: MemberOfService,
    private userGraphApiFacade: UserGraphApiFacade,
    private articlesGraphFacade: ArticlesFacade,
    private mainMenuGraphFacade: MainMenuGraphFacade,
    private sideItemsFacade: SideItemsGraphFacade,
    private bannerFacade: BannerFacade,
    private dashboardApiFacade: DashboardsApiFacade,
    private outlookCalendarGraphFacade: OutlookCalendarGraphFacade,
    private menuSidePopupGraphFacade: MenuSidePopupGraphFacade,
    private dashboardService: DashboardService,
    private announcementsGraphFacade: AnnouncementsGraphFacade,
    private userApiFacade: UserApiFacade,
    private tasksFacade: TasksFacade,
    private router: Router,
    private userProfileService: UserProfileService,
    private gaSevice: GoogleTagService) {
  }

  ngOnInit(): void {
    this.dashboardService.isOpened$.subscribe(status => {
      this.isDashboardOpen = status;
    });
    this.dashboardSourceSubscription = this.dashboardApiFacade.dashboardSource$.subscribe(source => {
      this.displaySelfyModule = source.userPermitions.displaySelfyModule;
    })

    this.userApiFacade.getUserBirthday().subscribe(birthday => {
      this.birthday = birthday;
    })

    this.userGraphApiFacade.getUserBirthDayBanner().subscribe(result => {
      this.birthDayBanner = result == false ? undefined : result;

      if (this.birthDayBanner) {
        this.birthDayBanner.isBirthday = true;
      }

    })
    this.sideCategoriesItemsSubscription = this.sideItemsFacade.sideCategoriesItems$.subscribe(items => {
      this.sideItemsCategories = items
    });

    this.bannerFacade.getMainManuBanner().subscribe(banners => {
      this.mainMenuFooterBanners = banners;
    })
    this.menuSidePopupGraphFacade.getSideMenuPopupBanners();

    this.outlookCalendarGraphFacade.getCalendarEvents().subscribe(result => {
      this.outlookCalendar = result;
    });

    this.mainMenuGraphFacade.mainMenu$.subscribe(result => {
      this.mainMenu = result;
    })

    this.userGraphApiFacade.userMostRecentPeople$.subscribe(result => {
      this.mostRecentPeople = result;
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.user = user;
    })

    this.menuSidePopupGraphFacade.sideMenuPopupBanners$.subscribe(result => {
      if (result && result.length > 0) {
        result.forEach(element => {
          if (element.location == 'top') {
            this.topBanner = element;
          }

          if (element.location == 'middle') {
            this.middleCard = element;
          }

          if (element.location == 'left') {
            this.leftCard = element;
          }
        });
      }
    })

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {

        this.isDashboardOpen = false;
      }

      if (event instanceof NavigationEnd) {
        this.dashboardService.close();
        setTimeout(() => {
          this.userApiFacade.getUserBirthday().subscribe(birthday => {
            this.birthday = birthday;
            if (birthday != null && birthday != undefined) {
              let year = this.datepipe.transform(Date.now(), 'YYYY');
              let dayAndMonth = this.datepipe.transform(birthday.dateOfBirth, 'MM-dd');

              if (dayAndMonth == this.todayBirthdays || dayAndMonth == this.yestodayBirthdays) {
                let currentBirthday = `${year}-${dayAndMonth}`;
                this.userApiFacade.getCountUserBirthdayWishes(currentBirthday);
              }
            }
          })
        }, 5000);

        setTimeout(() => {
          this.announcementsGraphFacade.getUserCountNotificationsToSee();
          this.tasksFacade.getUserCountTasksToSee();
        }, 10000);

      }
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    if (this.isDashboardOpen) {
      this.sidebarDashboardWidth = this.getSidebarDashboardWidth();
    }
  }

  getSidebarDashboardWidth(): number {


    return window.innerWidth >= this.sidebarDashboardMaxWidth + 274
      ? this.sidebarDashboardMaxWidth : window.innerWidth - 274;
  }

  onDashboardOpenClicked() {
    setTimeout(() => {
      let eventName = 'SELFY_CLICK';
      let eventDetails = {
        event_category: 'Selfy',
        event_label: 'Selfy watch'
      };
      this.gaSevice.sendGaEventWithUserProfile({ eventName: eventName, eventDetails: eventDetails, eventType: EnGaEventType.Click});

      this.dashboardApiFacade.getCurrentBudget().subscribe();
      this.sidebarDashboardWidth = this.getSidebarDashboardWidth();
      this.menuSidePopupGraphFacade.getSideMenuPopupBanners();
      this.outlookCalendarGraphFacade.getCalendarEvents().subscribe(result => {
        this.outlookCalendar = result;
      });
      this.isDashboardOpen = true;
      this.dashboardService.open();
      this.getUserBirthdayWishes();

      setTimeout(() => {
        this.canShow = true;
      }, 300);
    }, 0)
  }

  getUserBirthdayWishes() {

    if (this.birthday != null) {
      let year = this.datepipe.transform(Date.now(), 'YYYY');
      let dayAndMonth = this.datepipe.transform(this.birthday.dateOfBirth, 'MM-dd');

      if (this.birthday && (dayAndMonth == this.todayBirthdays || dayAndMonth == this.yestodayBirthdays)) {
        let currentBirthday = `${year}-${dayAndMonth}`
        this.userApiFacade.getUserBirthdayWishes(currentBirthday).subscribe(wishes => {
          this.birthdayWishes = wishes;
        });
      }
    }
  }

  onDashboardCloseClicked() {
    this.canShow = false;
    this.isDashboardOpen = false;
    this.dashboardService.close();
  }

  closeHeaderModalEmitter() {
    this.closeModalEmiter.emit();
  }

  onDashboardStateChanging(event: AnimationEvent) {
    if (event.phaseName == "start") {
      this.isDashboardChangingState = true;
    } else {
      this.isDashboardChangingState = false;
    }

    this.dashboardService.isOpening$.next(this.isDashboardChangingState)
  }

  onDashboardModeSwitched(isDarkMode: boolean): void {
    this.isDarkMode = isDarkMode;
  }

  refreshCalendar() {
    this.outlookCalendarGraphFacade.getCalendarEvents().subscribe(result => {
      this.outlookCalendar = result;
    });
  }

  ngOnDestroy(): void {
    this.sideCategoriesItemsSubscription.unsubscribe();
  }
}

