<div (click)="closeHeaderModalEmitter()" class="profile-container" *ngIf="userBasicInfo && userProfileInfo"
  [ngClass]="{'dashboard-opened': isDashboardOpened}">

  <div class="profile-menu">
    <!--external employee -->
    <a *ngIf="!displaySelfyModule || displaySelfyModule == null" class="profile"
      (click)="navigateToPersonalZone()" [routerLinkActive]="['menu-item--active']"
      [routerLinkActiveOptions]="{ exact: true }">
      <div class="profile__photo">
        <img *ngIf="userBasicInfo.birthday && userBasicInfo.birthday == today" class="profile__photo-birthday"
          src="/assets/images/global/birthday.png" />
        <img [style]="userBasicInfo.photo ? {border: '3px solid #fff'}: {border: '3px solid rgba(255, 255, 255, 0)'}"
          [src]="userBasicInfo.photo || '/assets/images/global/no-avatar.png'" alt="profile-photo" />
      </div>
      <div class="profile__text">
        <!-- <div class="profile__new">
          New
        </div> -->
        <div class="profile__name">
          Hi, {{ userBasicInfo.givenName }}
        </div>
        <div class="profile__post">
          <ng-container *ngIf="displaySelfyModule != undefined"> My profile</ng-container>
        </div>
      </div>
    </a>
    <!--playtica employee -->
    <div *ngIf="displaySelfyModule" (click)="navigateToPersonalZone()" class="profile">
      <div class="profile__photo">
        <img *ngIf="userBasicInfo.birthday && userBasicInfo.birthday == today" class="profile__photo-birthday"
          src="/assets/images/global/birthday.png" />
        <img [style]="userBasicInfo.photo ? {border: '3px solid #fff'}: {border: '3px solid rgba(255, 255, 255, 0)'}"
          [src]="userBasicInfo.photo || '/assets/images/global/no-avatar.png'" alt="profile-photo" />
      </div>
      <div class="profile__text">
        <div class="profile__name">
          Hi, {{ userBasicInfo.givenName }}
        </div>
        <div class="profile__post">
          <ng-container *ngIf="displaySelfyModule != undefined"> Selfy zone</ng-container>
        </div>
      </div>
      <div *ngIf="!isDashboardOpened && displaySelfyModule"
        class="sidebar-open-button-container">
        <div>
          <img src="/assets/makeover/left-menu/selfy-arrow-right.svg" alt="">
        </div>
      </div>
    </div>
    <div class="profile-divider"></div>

    <div>

      <app-side-bar-categories [sideItemsCategories]="sideItemsCategories"></app-side-bar-categories>
    </div>

  </div>
  <app-my-apps [apps]="allApps" [elementsToShow]="3"></app-my-apps>

  <ng-container *ngFor="let banner of banners">
    <div *ngIf="banner" class="banner">
      <a href="{{banner.link}}">
        <img src="{{banner.image}}" alt="">
      </a>
    </div>
  </ng-container>

  <div class="edit-mode">
    <button class="edit-mode__button on"
      *ngIf="!isEditModeActive && (isMainSiteAdmin == true || isWikiSiteAdmin == true)"
      (click)="isEditMenuModeChange(true)">
      <img src="/assets/images/global/edit-app-pencil.png" />
      Turn on edit mode
    </button>
    <button class="edit-mode__button off"
      *ngIf="isEditModeActive  && (isMainSiteAdmin == true || isWikiSiteAdmin == true)"
      (click)="isEditMenuModeChange(false)">
      <img src="/assets/images/global/edit-app-remove.png" />
      Turn off edit mode
    </button>
  </div>
</div>